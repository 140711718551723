import {
  Flex,
  Text,
  Box,
  Link,
  Button,
  CheckmarkCircleIcon,
  useMatchBreakpoints,
  useOnClickOutside,
} from 'packages/uikit'
import { useState } from 'react'
import { usePopper } from 'react-popper'
import { CHAIN_ID } from 'config/constants/networks'
import styled from 'styled-components'

const NETWORKS = [
  {
    chainId: 33139,
    title: 'ApeChain',
    href: '/',
    icon: '/images/chain/ape-chain.svg',
  },
  {
    chainId: 2818,
    title: 'Morph',
    href: 'https://morph.moodeng.fi/',
    icon: '/images/chain/morph-icon.jpg',
  },
  {
    chainId: 918,
    title: 'SlerfChain',
    href: 'https://slerf.moodeng.fi/',
    icon: '/images/chain/slerf.png',
  },
  {
    chainId: 130,
    title: 'Unichain',
    href: 'https://unichain.moodeng.fi/',
    icon: '/images/chain/unichain.jpg',
  },
  {
    chainId: 43111,
    title: 'Hemi',
    href: 'https://hemi.moodeng.fi/',
    icon: '/images/chain/hemi.png',
  },
]

const SelectNetwork = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null)
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(targetRef, tooltipRef, {
    strategy: 'absolute',
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [0, -10] } }],
  })

  const handleToggle = () => setIsOpen((s) => !s)
  const { isMobile } = useMatchBreakpoints()
  const findDefaultChain = NETWORKS?.filter((nw) => nw.chainId === +CHAIN_ID)

  useOnClickOutside({ current: targetRef }, () => setIsOpen(false))

  return (
    <>
      <Box flexDirection="column" ref={setTargetRef}>
        <Button variant="text" onClick={handleToggle} px="12px">
          <Flex alignItems="center">
            <img
              src={findDefaultChain[0]?.icon || '/images/chain/ape-chain.svg'}
              alt="chain-icon"
              style={{ borderRadius: '50%' }}
            />
            <Text fontSize={['20px']}>{findDefaultChain[0]?.title || 'ApeChain'}</Text>
          </Flex>
        </Button>
        <Box position="relative">
          {isOpen ? (
            <BoxSelect
              style={styles.popper}
              ref={setTooltipRef}
              background="var(--color-white)"
              borderRadius="20px"
              padding="10px 20px"
              mt="20px"
              {...attributes.popper}
            >
              <Box width="100%">
                {NETWORKS.map((nw) => {
                  const isDisabled = !nw.href || nw.href === '#'

                  return (
                    <Flex justifyContent="space-between" alignItems="center" key={nw.chainId}>
                      <Button
                        variant="text"
                        px="0"
                        as={Link}
                        disabled={isDisabled}
                        color="var(--color-text)"
                        external={!isDisabled}
                        href={nw.href}
                      >
                        <Flex alignItems="center">
                          <img
                            width={isMobile ? 30 : 40}
                            src={nw.icon}
                            alt="chain-icon"
                            style={{ borderRadius: '50%' }}
                          />
                          <Text>{nw.title}</Text>
                        </Flex>
                      </Button>
                      {nw.chainId === +CHAIN_ID ? <CheckmarkCircleIcon /> : null}
                    </Flex>
                  )
                })}
              </Box>
            </BoxSelect>
          ) : null}
        </Box>
      </Box>
    </>
  )
}

export default SelectNetwork

const BoxSelect = styled(Flex)`
  box-shadow: 0px 1px 0px 2px rgba(0, 0, 0, 1);
  width: 287px;
`
